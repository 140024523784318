import React, { ReactElement } from 'react';
import { graphql, PageProps } from 'gatsby';
import { RichTextData } from '../components/RichText';
import { TitleArea } from '../components/TemplateHelpers/1-TitleArea';
import { ContentArea } from '../components/TemplateHelpers/3-ContentArea';

export type GenericQuery = {
  contentfulPageGeneric: {
    title: string;
    subtitle?: string;
    content?: RichTextData;
    showTableOfContents?: boolean;
  };
};

const Generic = ({ data }: PageProps<GenericQuery>): ReactElement => {
  const { title, subtitle, content, showTableOfContents } =
    data.contentfulPageGeneric;

  return (
    <>
      <TitleArea variant={'generic'} title={title} subtitle={subtitle} />
      <ContentArea
        variant={'generic'}
        content={content}
        showTableOfContents={showTableOfContents}
      />
    </>
  );
};

export default Generic;

export const query = graphql`
  query ($id: String!) {
    contentfulPageGeneric(id: { eq: $id }) {
      ... on ContentfulPageGeneric {
        ...Generic
      }
    }
  }
`;
